import React from 'react';

import './Title.scss';

const Title = () => {
  return (
    <div id='title'>
      <h1>Karen Kwok</h1>
      <div id='title-subtext'>
        <div id='emoji-1'>💻</div>
        <div id='emoji-2'>🎓</div>
        <div id='emoji-3'>📍</div>
        <div id='text-1'>Software Developer</div>
        <div id='text-2'>University of Toronto '21</div>
        <div id='text-3'>Toronto, ON</div>
      </div>
    </div>
  );
};

export default Title;
